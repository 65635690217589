import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { MessageService } from '../../services/message.service';
import { any } from 'codelyzer/util/function';
import { AuthService } from 'src/app/authentication/auth.service';
import { DatePipe } from '@angular/common';
import { ApiEndpoints } from '../../constants/constants';
import { CommonAPIService } from '../../services/common-api.service';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { StaticPageJSHelper } from 'src/app/helper/javascript-shared-helper';
import { ChangePhoneNumberComponent } from 'src/app/pages/profile/change-phone-number/change-phone-number.component';
import { DialogServices } from '../../common-dialogue/dialogs.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  searchVisible: boolean;
  quickViewVisible: boolean;
  isFolded: boolean;
  isExpand: boolean;
  loading1: boolean;
  id = any;
  loading: boolean;
  data: any;
  farmData: any;
  selectedFarm: any;
  selFarm: string;
  date = '';
  time = '';
  public value = new Date();
  constructor(
    private themeService: ThemeConstantService,
    public commonService: MessageService,
    private commonAPIService: CommonAPIService,
    public messageService: MessageService,
    private authService: AuthService,
    public datepipe: DatePipe,
    private router: Router,
    private dialogServices: DialogServices
  ) {
    setInterval(() => {
      const currentDate = new Date();
      this.date = currentDate.toDateString();
      this.time = currentDate.toLocaleTimeString();
    }, 1000);
  }
  selectedTeam = '';
  onSelected(): void {
    this.commonAPIService
      .getPromiseResponse(
        ApiEndpoints.changeFarmId,
        'post',
        { farm_id: this.selFarm },
        [],
        true
      )
      .then((q) => {
        this.authService.RefreshToken().subscribe((t) => {
          this.router.navigateByUrl('/').then(() => {
            window.location.reload();
          });
        });
      });
  }

  ngOnInit(): void {
    StaticPageJSHelper.loadScript();

    this.authService.CurrnetLoggedInUserSubscribe().subscribe((s) => {
      this.data = s;

      this.selFarm = this.data.farm_id;
    });
    this.farmDetails();
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
  }
  farmDetails() {
    this.commonAPIService
      .getPromiseResponse(ApiEndpoints.dropdown.farmData, 'get', null, [], true)
      .then((q) => {
        this.farmData = q.data;
      });
  }

  // tslint:disable-next-line:typedef
  logout(): void {
    this.commonService.showSuccess('Logout Successfully ');
    this.authService.Logout();
    // this.router.navigate(['/auth']);
    // this.ngOnDestroy();
    return;
  }

  // tslint:disable-next-line:typedef
  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  // tslint:disable-next-line:typedef
  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }
  resetLan() {
    var iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
    if (!iframe) return;
    //@ts-ignore
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var restore_el = innerDoc.getElementsByTagName("button");

    for (var i = 0; i < restore_el.length; i++) {
      if (restore_el[i].id.indexOf("restore") >= 0) {
        restore_el[i].click();
        var close_el = innerDoc.getElementsByClassName("goog-close-link");
        close_el[0].click();
        return;
      }
    }
  }

  openDilog() {
    this.dialogServices.createComponentModal(
      'Update Phone Number',
       ChangePhoneNumberComponent
    );
  }
}
